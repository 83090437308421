.community_home {
  margin: var(--nav-medium-height) 0px 0px var(--nav-medium-width);
}
.community_img {
  width: 600px;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .community_home {
    margin: 50px 10px;
  }
  .community_img {
    width: 100%;
    margin-top: 120px;
  }
}
@media (max-width: 376px) {
}
