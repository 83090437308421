.nav_menu {
  width: 250px;
  position: fixed;
  top: 4.5rem;
  //padding-top: 5rem;
  left: -100%;
  height: 100%;
  z-index: 100;
  background: linear-gradient(#fed9d8, #99ccff);
  overflow-y: auto;
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
}
.nav_menu::-webkit-scrollbar {
  display: none; /* 크롬, 사파리, 오페라, 엣지 */
}

.nav_menu.active {
  left: 0;
}
.navmenu_add {
  width: 250px;
  height: 60px;
  background-color: #8ebff0;
  position: fixed;
  bottom: 0;
  list-style: none;
  display: flex;
  cursor: pointer;

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #161616;
    width: 250px;
    height: 60px;
    text-align: center;
    span {
      margin-left: 10px;
    }
  }
  &:hover {
    background: #fed9d8;
    transition: 150ms;
    p {
      color: #161616;
    }
  }
}

.nav_text {
  padding: 8px;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 10px;
  position: relative;

  span {
    text-decoration: none;
    color: #2e2e2e;
  }

  &:hover {
    background: #fff;
    transition: 150ms;
    border-radius: 10px;
    span {
      color: #161616;
    }
  }
}

.navmenu_items {
  width: 100%;
  height: 100%;
  background: linear-gradient(#fed9d8, #99ccff);
  //background-color: #9ecdfd;
  //background: linear-gradient(#4093a5, #276c7c);
  list-style: none;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.faiconContent {
  display: flex;
  .fapen {
    margin-right: 6px;
  }
}
.nav_name {
  display: flex;
  align-items: center;

  width: 10rem;
  height: 3rem;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
.modalContent {
  background-color: white;
  padding: 2rem;
  width: 20rem;
  height: 15rem;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button {
    &:nth-child(1) {
      background-color: grey;
      width: 6rem;
      height: 45px;
      border-radius: 5px;
      margin-right: 8px;
      &:hover {
        background-color: #a7a8b1;
      }
    }
    color: white;
    width: 6rem;
    height: 45px;
    border-radius: 5px;
    background-color: rgb(255, 64, 76);
    &:hover {
      background-color: #ff7982;
    }
  }

  h2 {
    font-size: 1rem;
    font-weight: 600;
  }
  input {
    width: 100%;
    font-size: 1rem;
  }
  p {
    font-size: 1rem;
  }
}
.modalinner {
  height: 190px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  h2 {
    font-size: 1em;
  }
}

@media (max-width: 767px) {
  .nav_menu {
    //top: 3.4em;
    width: 250px;
    position: fixed;
    top: 8.5rem;
  }
}
@media (max-width: 376px) {
}
