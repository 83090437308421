.inputLabel {
  font-size: 1.2rem;
  font-weight: bold;
}
.accessForm {
  margin: 30px 0px;
  display: flex;
  flex-direction: column;

  input[type="file"] {
    width: 0;
    height: 0;
    opacity: 0;
    padding: 0;
    border: 0;
  }
}

@media (max-width: 768px) {
}

@media (max-width: 376px) {
}
