.deleteDiv {
  padding: 20px;
  .deleteTitle {
    font-size: 1.5rem;
    text-align: center;
  }

  .deleteBtnDiv {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  button {
    color: black;
    font-size: 1.2rem;
    min-height: 48px;
    background-color: transparent;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    border-top: 1px solid rgb(var(--ig-elevated-separator));
    &:hover {
      background-color: black;
      color: white;
    }
    &:nth-child(1) {
      color: red;
    }
  }
}
