.postForm {
  border-radius: 30px;
  width: 100%;

  .postFormHeader {
    padding: 15px 15px 10px 15px;
    display: flex;
    justify-content: space-between;
    h3 {
      margin: 0;
      font-weight: bold;
      font-size: 1.2rem;
    }
    p {
      margin: 0;
    }
  }

  .postFormMain {
    display: flex;
    height: 500px;
    padding: 10px;
    margin: 25px 0;
    p {
      color: red;
      font-size: 0.8rem;
      &:before {
        display: inline;
        content: "⚠ ";
      }
    }
    .fileForm {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-radius: 5px;
      height: 450px;
      padding: 10px;
      input {
        opacity: 0;
        z-index: 100;
        width: 100px;
        height: 130px;
      }
      button {
        position: absolute;
        border: 0;
        background-color: transparent;
      }
      .previewImg {
        height: 100%;
      }
    }

    .postFormDiv {
      background-color: beige;
      width: 50%;
      height: 80%;
      padding: 20px;

      .postFormNickname {
        display: flex;
        margin-top: 10px;
      }
    }
  }
}

@media (max-width: 767px) {
  .postFormMain {
    flex-direction: column;
    margin-top: 100px;
  }
  .postForm {
    padding: 5px 0px 0px 0px;
    border-radius: 10px;
    width: 100%;
    height: 100vh;
  }
}

@media (max-width: 376px) {
}
