.searchWrapper {
  width: 300px;
  height: 40px;
  border-radius: 50px;
  padding: 10px 15px 10px 20px;
  margin: 0;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
}
.searchInput {
  border: none;
  outline: none;
  flex: 1;
  background-color: #f5f5f5;
}
.searchDiv {
  padding: 5px;
  cursor: pointer;
}
.searchIcon {
  width: 20px;
  height: 20px;
}
.autoSearchContainer {
  z-index: 3;
  width: 300px;
  background-color: #fff;
  position: absolute;
  overflow: auto;
  top: 72px;
  padding: 15px;
  box-shadow: 4px 5px 10px 0 rgba(0, 0, 0, 0.2);
  li {
    list-style: none;
  }
}
.autoSearchData {
  padding: 0px 8px;
  width: 100%;
  height: 50px;
  font-size: 14px;
  font-weight: bold;
  z-index: 4;
  letter-spacing: 2px;
  position: relative;
  display: flex;

  &:hover {
    background-color: #edf5f5;
    cursor: pointer;
  }

  span {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  a {
    width: 100%;
    height: 100%;
    display: inline-block;
  }
}

@media (max-width: 767px) {
  .autoSearchContainer {
    z-index: 3;
    width: 80%;
    background-color: #fff;
    overflow: auto;
    top: 136px;
    padding: 15px;
    box-shadow: 4px 5px 10px 0 rgba(0, 0, 0, 0.2);
    li {
      list-style: none;
    }
  }
  .searchWrapper {
    width: 80%;
    height: 40px;
    background-color: #f5f5f5;
    margin: 0;
    display: flex;
  }
}
@media (max-width: 376px) {
}
