.drop_list {
  background-color: #fff;
  box-shadow: 0 0.125rem 0.4rem rgba(0, 0, 0, 0.075);
  width: 100%;
  height: 100%;
}
.drop_listItem {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  list-style: none;

  &:hover,
  &:focus {
    cursor: pointer;
    font-weight: bold;
    background-color: #edf5f5;
    transition: 150ms;
  }

  span {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #161616;
  }

  a {
    width: 100%;
    height: 100%;
    display: inline-block;
  }
}

.fofile_box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  //border: 1px solid black;
}
.myFrofile {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.dropList {
  right: 0;
  width: 180px;
  position: absolute;
  top: 72px;
  transition: 850ms;
}
.dropList.active {
  transition: 350ms;
}
@media (max-width: 767px) {
  // .drop_list {
  //   position: relative;
  //   top: 56px;
  // }
  .dropList {
    top: 136px;
  }
  .fofile_box {
    margin-left: 8px;
  }
}
@media (max-width: 376px) {
}
