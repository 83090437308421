.writerName {
  display: flex;
  padding: 15px 0;
  h1 {
    font-size: 0.6rem;
    margin-left: 5px;
  }
  p {
    font-size: 0.9rem;
    font-weight: bold;
  }
}
.feedDetailDiv {
  margin: 0 auto;
  max-height: 500px;
  max-width: 100%;
  overflow-y: scroll;

  /* ( 크롬, 사파리, 오페라, 엣지 ) 동작 */
  &::-webkit-scrollbar {
    display: none;
  }

  img {
    width: 100%;
  }

  .feedTitle {
    margin: 10px 0 0 0;
    font-weight: bold;
    font-size: 1.4rem;
  }
  .feedDescription {
    margin: 5px 0 0 0;
  }
}
.commentInput {
  border-top: 1px solid #bec1c7;
  opacity: 0.8;
  display: flex;
  padding: 20px;
  height: 70px;
  justify-content: space-around;
  textarea {
    width: 80%;
    resize: none;
  }
}
