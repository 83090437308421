.signupCard {
  display: flex;
  justify-content: center;
  padding: 40px 0px;
}

@media (max-width: 650px) {
  .signupCard {
    flex-direction: column;
    align-items: center;
  }
}
