.landingHome {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.header {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #e3e3e3;
  background-color: #fff;
}
.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    font-weight: 700;
    color: #c62917;
    font-size: 23px;
  }
  img {
    width: 40px;
  }
}
.landing_content {
  width: 100%;
  height: 685px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
}
.login_btn {
  padding: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 28px;
  border-radius: 3px;
  color: #fff;
  font-size: 12px;
  background-color: #ff404c;
  cursor: pointer;
  h1 {
    font-size: 13px;
  }
}
.footer {
  width: 100%;
  display: flex;
  background-color: #fff;
}
.footerContent {
  display: flex;
  margin: 0 auto;
  ul {
    padding: 16px 0;
  }
  li {
    list-style: none;
    display: inline-block;
    margin: 0 8px;
    font-size: 13px;
  }
}
.screen_le {
  width: 550px;
  height: 500px;
  position: relative;
  z-index: 1;
  background: transparent
    url("https://velog.velcdn.com/images/view_coding/post/6f20c295-cff1-455f-b903-6565a8e7e417/image.png")
    no-repeat;
  background-size: 550px;
  background-position: center;
}
.screen_ri {
  width: 380px;
  padding-top: 50px;
  h1 {
    line-height: 40px;
    font-size: 1.5rem;
  }
  strong {
    color: #ff404c;
    font-size: 35px;
  }
}
.home {
  width: 378px;
  height: 230px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  background: transparent
    url("https://velog.velcdn.com/images/view_coding/post/97243610-6adf-4e65-b795-559944f04640/image.png")
    no-repeat;
  background-size: 378px;
}
.board {
  width: 378px;
  height: 230px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  animation: active-init-board 6s infinite;
  background: transparent
    url("https://velog.velcdn.com/images/view_coding/post/ce9fe3fe-bfd6-4db6-bac2-4e47730bed9a/image.png")
    no-repeat;
  background-size: 378px;
}
.timetable {
  width: 378px;
  height: 230px;
  opacity: 0;
  animation: active-init-timetable 6s infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: transparent
    url("https://velog.velcdn.com/images/view_coding/post/cf7a626d-2fa5-4c9d-9101-cbfccc7ec20c/image.png")
    no-repeat;
  background-size: 378px;
}
@keyframes active-init-board {
  0% {
    opacity: 0;
  }
  59% {
    opacity: 0;
  }
  67% {
    opacity: 1;
  }
  92% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes active-init-timetable {
  0% {
    opacity: 0;
  }
  26% {
    opacity: 0;
  }
  34% {
    opacity: 1;
  }
  67% {
    opacity: 1;
  }
  68% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@media (max-width: 767px) {
  .landingHome {
    height: 100%;
  }
  .landing_content {
    flex-direction: column;
  }
  .screen_le {
    width: 400px;
    height: 350px;
    background-size: 400px;
  }
  .screen_ri {
    width: 380px;
    padding-top: 20px;
    margin-left: 0;
    margin-top: 10px;
    text-align: center;
    h1 {
      font-size: 20px;
    }
    strong {
      font-size: 25px;
    }
  }
  .home,
  .board,
  .timetable {
    width: 278px;
    height: 170px;
    background-size: 278px;
  }
}
@media (max-width: 376px) {
}
