.cropImg {
  height: 400px;
}

.sliderDiv {
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-around;
  .slider {
    width: 90%;
  }
}
