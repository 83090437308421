.main {
  margin: var(--nav-medium-height) 0px 0px var(--nav-medium-width);
}

.feeds {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 100%;
}

.feedDiv {
  position: relative;
  padding: 50px 20px;
  margin: 0 auto;
  width: 500px;
  border-bottom: 1px solid #bec1c7;

  .feedUser {
    display: flex;
    margin: 0 0 25px 0;
    width: 100%;
    h1 {
      font-size: 0.6rem;
      line-height: 1.3;
      margin-left: 5px;
      p {
        font-size: 1rem;
        font-weight: bold;
      }
    }
  }

  .feedMenu {
    position: absolute;
    top: 50px;
    right: 30px;
  }
  .dropDownBtn {
    svg {
      pointer-events: none;
    }
    path {
      pointer-events: none;
    }
  }

  .menu {
    position: absolute;
    z-index: 100;
    width: 150px;
    right: -10px;
    top: 50px;
    list-style: none;
    background-color: white;
    // border: 1px solid gray;
    margin: 0;
    padding: 0;
    font-size: 1rem;
    text-align: center;
  }
  .disable {
    display: none;
  }
  .menuList {
    padding: 15px;
    border: 0.5px solid gray;
    &:hover {
      background-color: #bec1c7;
      color: white;
    }
  }
  img {
    width: 100%;
  }
}
.comment {
  margin-top: 10px;
  cursor: pointer;
}

@media (max-width: 767px) {
  .main {
    margin: 10px;
  }
  .feeds {
    display: flex;
    align-items: center;

    margin: 120px 0 10px 0px;
    width: 100%;
    flex-direction: column;
  }
  .feedDiv {
    width: 100%;
    .menu {
      position: absolute;
      z-index: 100;
      width: 30vw;
      right: -5px;
      list-style: none;
      background-color: aliceblue;
      margin: 0;
      padding: 0;
      font-size: 1rem;
    }
    .menuList {
      padding: 15px;
      &:hover {
        background-color: black;
        color: white;
      }
    }
  }
}

.sideDiv {
  height: 100%;
  width: 35%;
  position: sticky;
  right: 3%;
  top: 15%;
  scroll-behavior: smooth;
}

@media (max-width: 1150px) {
  .feeds {
    width: 100%;
  }
  .sideDiv {
    display: none;
  }
}
